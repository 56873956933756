import {configureStore} from "@reduxjs/toolkit"
import authReducer from "./authReducer"
import estadoReducer from "./estadoReducer"
import modoReducer from "./modoReducer"

const store = configureStore({
    reducer: {
        estado: estadoReducer,
        auth: authReducer,
        modo: modoReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
