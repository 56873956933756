import React from "react";

type CheckEstadoProps = {
    estado: boolean,
    textosino?: boolean,
    times?: boolean,
}

const CheckEstado: React.FC<CheckEstadoProps> = (props) => {
    const estilo = props.estado ? 'fas fa-check-circle text-success' :
        (props.times ? 'fas fa-times-circle' : 'fas fa-exclamation-circle') + ' text-danger'

    if (props.textosino) {
        return (
            <>
                <i className={estilo}/>
                <span className={'ms-2 ' + (props.estado ? 'text-success' : 'text-danger')}>
                    {props.estado ? 'Sí' : 'No'}
                </span>
            </>
        )
    } else {
        return (
            <i className={estilo}/>
        )
    }
}

export default CheckEstado
