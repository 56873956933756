import React from "react";
import './IconoCarga.css'

type Props = {
    chico?: boolean,
    espacio?: boolean
}

const IconoCarga: React.FC<Props> = ({chico, espacio}) => {
    return (
        <div className={'text-center w-100' + (chico ? ' mb-2' : ' mb-3') +
        (espacio ? (chico ? ' mt-3' : ' mt-4') : '')}>
            <span className={'spinner-border text-primary' + (chico ? ' iconocargachico' : ' iconocarga')}/>
        </div>
    )
}

export default IconoCarga